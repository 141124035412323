import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { IntercomAPI } from 'react-intercom'
import lang from '../utils/lang'
import { formatWorkspaceName } from '../utils/helperFunctions'
import IfAuthorized from './IfAuthorized'
import { validateEmail } from '../utils/validators'
import { contextualMenuService } from '../services/contextualMenu.service'
import { workspacesService } from '../services/workspaces.service'
import NewFeaturesList, { LATEST_FEATURE } from './GUI/NewFeaturesList'
import { loadLastNotifiedFeature } from '../utils/localStorage'
import { isCompanyAdmin, isCompanyOrSuperAdmin, isSuperAdmin, isSuperAdminImpersonating } from '../reducers/user'
import AccountMenu from './GUI/AccountMenu'
import { history } from '../store'
import { connect } from 'react-redux'
import { setWorkspacesList } from '../actions/workspaces'
import { getGravatarImage, getGravatarProfileUrl } from '../services/gravatar.service'
import { showFailedToContactServerError } from '../utils/errors'
import { openModalDialog } from '../actions/modalDialog'
import logo from '../assets/seatsio-logo-white.svg'
import Badge from './GUI/Badge'
import {userService} from '../services/user.service'
import {updateUser} from '../actions/user'

const helpMenuOptions = [
    {
        type: 'documentation',
        caption: lang.d('documentation'),
        link: 'https://docs.seats.io'
    },
    {
        type: 'help',
        caption: lang.d('help_center'),
        link: 'http://support.seats.io'
    },
    {
        type: 'new-notification',
        caption: lang.d('whats_new'),
        uiEvent: 'whatsNew'
    }
]

const liveHelpMenuOption = [{
    type: 'live-help',
    caption: lang.d('live_support'),
    uiEvent: 'liveSupport'
}]

class Navigation extends React.PureComponent {

    constructor (props) {
        super(props)
        this.state = {
            accountMenuOpened: false,
            contextualHelpMenuOpened: false,
            workspacesMenuOpened: false,
            lastNotifiedFeature: loadLastNotifiedFeature(),
            checkedForUser: false
        }
        this.helpDropdownRef = React.createRef()
        this.workspacesDropdownRef = React.createRef()
    }

    componentDidMount () {
        this.loadWorkspaces()
    }

    componentDidUpdate (prevProps) {
        if (prevProps.context.company !== this.props.context.company) {
            this.loadWorkspaces()
        }
        if (this.isAwaitingCompletionOfUpgrade()) {
            this.watchForUpdatedUser()
        }
    }

    async loadWorkspaces () {
        try {
            const workspaces = await workspacesService.listFirstPage(100)
            this.props.setWorkspacesList(workspaces.items, workspaces.nextPageStartsAfter)
        } catch (e) {
            showFailedToContactServerError()
        }
    }

    onHelpMenu() {
        if (this.props.user.liveSupportEnabled) {
            this.showHelpMenu(helpMenuOptions.concat(liveHelpMenuOption))
        } else {
            this.showHelpMenu(helpMenuOptions)
        }
    }

    showHelpMenu (options) {
        const menuBoundingRect = this.helpDropdownRef.current.getBoundingClientRect()
        this.setState({ contextualHelpMenuOpened: true })
        contextualMenuService.show(options, {
            position: {
                right: window.innerWidth - menuBoundingRect.right,
                top: menuBoundingRect.top + menuBoundingRect.height
            },
            onSelect: this.onHelpMenuEvent.bind(this),
            onClose: () => this.setState({ contextualHelpMenuOpened: false })
        })
    }

    onHelpMenuEvent (uiEvent) {
        this.setState({ contextualHelpMenuOpened: false })
        switch (uiEvent) {
        case 'whatsNew':
            this.showWhatsNew()
            break
        case 'liveSupport':
            IntercomAPI('show')
            break
        default:
        }
    }

    onNewFeaturesButton () {
        this.showWhatsNew()
    }

    showWhatsNew () {
        this.setState({ lastNotifiedFeature: LATEST_FEATURE })
        this.props.openModalDialog({
            settings: { component: <NewFeaturesList/> }
        })
    }

    openAccountMenu () {
        this.setState({ accountMenuOpened: true })
    }

    onAccountMenuSelect (uiEvent) {
        this.setState({ accountMenuOpened: false })
    }

    onAccountMenuLink (link) {
        this.setState({ accountMenuOpened: false })
        history.push(link)
    }

    onAccountMenuClose () {
        this.setState({ accountMenuOpened: false })
    }

    getWorkspacesOptions () {
        const workspacesList = this.props.workspacesList
            .filter(workspace => workspace.isActive) // TODO: delete when all users have refreshed their workspaces
            .slice(0, 10)
            .map(workspace => (
                {
                    caption: formatWorkspaceName(workspace),
                    uiEvent: `switch:${workspace.key}`,
                    selected: workspace.key === this.props.context.workspace.key
                }
            ))
        const toAllWorkspaces = [
            {
                type: 'separator'
            },
            {
                caption: lang.d('all_workspaces'),
                uiEvent: 'all_workspaces'
            }
        ]
        return [
            ...workspacesList,
            ...toAllWorkspaces
        ]
    }

    onWorkspaceSelect (uiEvent) {
        this.setState({ workspacesMenuOpened: false })
        const [action, workspaceKey] = uiEvent.split(':')
        if (action === 'all_workspaces') {
            history.push('/workspaces')
        }
        if (action === 'switch') {
            this.switchToWorkspace(workspaceKey)
        }
    }

    async openWorkspacesMenu () {
        const menuBoundingRect = this.workspacesDropdownRef.current.getBoundingClientRect()
        this.setState({ workspacesMenuOpened: true })
        contextualMenuService.show(this.getWorkspacesOptions(), {
            position: {
                left: menuBoundingRect.left,
                top: menuBoundingRect.top + menuBoundingRect.height
            },
            onSelect: this.onWorkspaceSelect.bind(this),
            onClose: () => this.setState({ workspacesMenuOpened: false })
        })
    }

    switchToWorkspace (workspaceKey) {
        history.push(`/switch-to-workspace/${workspaceKey}`)
    }

    getUserDisplayName (user) {
        return user.email && validateEmail(user.email) ? user.email : (user.name || lang.d('anonymous'))
    }

    renderUpgradeButton () {
        if (isCompanyOrSuperAdmin(this.props.user)) {
            if (!this.props.user.company.hasChosenSubscriptionPlan) {
                return <Link to="/account/upgrade-now" className="upgrade-button">{lang.d('upgrade')}</Link>
            } else if (this.isAwaitingCompletionOfUpgrade()) {
                return <Link to="/account/payment-details" className="upgrade-button">{lang.d('complete_upgrade')}</Link>
            }
        }
        return null
    }

    isAwaitingCompletionOfUpgrade() {
        return this.props.user.company.hasChosenSubscriptionPlan && (!this.props.user.company.currentSubscriptionPlan || this.props.user.company.currentSubscriptionPlan.key === 'NOT_SET')
    }

    isPostSubscriptionCompanyOrSuperAdmin () {
        return isSuperAdmin(this.props.user) || (isCompanyAdmin(this.props.user) && this.props.user.company.hasChosenSubscriptionPlan)
    }

    renderAccountMenuUserOptions (user, isAdminImpersonating) {
        const options = []
        const userIsCompanyAdmin = isCompanyOrSuperAdmin(user)
        if (this.props.workspacesList.length > 1 && !userIsCompanyAdmin) {
            options.push({
                preset: 'contextual-menu-item',
                type: 'workspace',
                caption: lang.d('workspaces'),
                onMouseUp: () => this.onAccountMenuLink('/workspaces'),
                key: 'workspaces'
            })
        }
        if (!isAdminImpersonating) {
            options.push({
                preset: 'contextual-menu-item',
                type: 'settings',
                caption: lang.d('user-settings'),
                onMouseUp: () => this.onAccountMenuLink('/user-settings'),
                key: 'userSettings'
            })
        }

        return options
    }

    renderAccountMenuCompanyOptions (user, isAdminImpersonating) {
        const userIsAdmin = isCompanyOrSuperAdmin(user)
        const options = []
        if (userIsAdmin) {
            options.push({
                preset: 'contextual-menu-item',
                type: 'workspace',
                caption: lang.d('workspaces'),
                onMouseUp: () => this.onAccountMenuLink('/workspaces'),
                key: 'workspaces'
            },
            {
                preset: 'contextual-menu-item',
                type: 'subaccounts',
                caption: lang.d('team'),
                onMouseUp: () => this.onAccountMenuLink('/team'),
                key: 'team'
            },
            {
                preset: 'contextual-menu-item',
                type: 'usage',
                caption: lang.d('usage'),
                onMouseUp: () => this.onAccountMenuLink('/reports'),
                key: 'reports'
            })

            if (this.isPostSubscriptionCompanyOrSuperAdmin()) {
                options.push({
                    preset: 'contextual-menu-item',
                    type: 'invoice',
                    caption: lang.d('invoices'),
                    onMouseUp: () => this.onAccountMenuLink('/invoices'),
                    key: 'invoices'
                })
            }

            options.push(
                this.upgradeOrChangePlanItem(),
                {
                    preset: 'contextual-menu-item',
                    type: 'settings',
                    caption: lang.d('company-settings'),
                    onMouseUp: () => this.onAccountMenuLink('/company-settings'),
                    key: 'companySettings'
                }
            )
        }
        if (!isAdminImpersonating) {
            options.push({
                preset: 'contextual-menu-item',
                type: 'logout',
                caption: lang.d('logout'),
                onMouseUp: () => this.onAccountMenuLink('/logout'),
                key: 'logout'
            })
        }
        if (isAdminImpersonating) {
            options.push({
                preset: 'contextual-menu-item',
                type: 'return',
                caption: lang.d('return_to_original_account'),
                onMouseUp: () => this.onAccountMenuLink('/switch-to-default-company-and-workspace'),
                key: 'returnToOriginalAccount'
            })
        }
        return options
    }

    upgradeOrChangePlanItem () {
        if (isCompanyOrSuperAdmin(this.props.user) && !this.props.user.company.hasChosenSubscriptionPlan) {
            return {
                preset: 'contextual-menu-item',
                type: 'subscription',
                caption: lang.d('upgrade-plan'),
                onMouseUp: () => this.onAccountMenuLink('/account/upgrade-now'),
                key: 'subscription'
            }
        } else {
            return {
                preset: 'contextual-menu-item',
                type: 'subscription',
                caption: lang.d('subscription'),
                onMouseUp: () => this.onAccountMenuLink('/account/subscription'),
                key: 'subscription'
            }
        }
    }

    renderAccountMenuImpersonatorOptions (user, isAdminImpersonating) {
        const options = []
        if (isAdminImpersonating) {
            options.push({
                preset: 'contextual-menu-item',
                type: 'settings',
                caption: lang.d('user-settings'),
                onMouseUp: () => this.onAccountMenuLink('/user-settings'),
                key: 'userSettingsImpersonator'
            })
        }
        return options
    }

    getWorkspaceBadge (workspace) {
        if (workspace.isTest) {
            return <Badge key="test" type="test" dark>{ lang.d('test') }</Badge>
        }
        return null
    }

    async watchForUpdatedUser() {
        if(isSuperAdminImpersonating(this.props.user, this.props.context)) {
            return
        }

        if (!this.state.checkedForUser) {
            this.setState({checkedForUser: true})
            const user = await userService.retrieveCurrentUser(this.props.region)
            if (user.company && user.company.currentSubscriptionPlan && user.company.key !== 'NOT_SET') {
                this.props.updateUser(user)
            }
        }
    }

    render () {
        const { user, pathname, context } = this.props
        const newFeaturesNotifications = this.state.lastNotifiedFeature !== LATEST_FEATURE
        const isAdminImpersonating = isSuperAdminImpersonating(user, context)
        const showWorkspacesSwitcher = isCompanyAdmin(this.props.user) || this.props.workspacesList.length > 1

        return <div className="navigation">
            <div className="left-anchor">
                <div className="logo-and-status">
                    <Link to="/" className="logo">
                        <img src={logo} alt="Seats.io" />
                    </Link>
                    <div className="status offline">{lang.d('offline')}</div>
                </div>
                { newFeaturesNotifications &&
                    <div className="menu-button">
                        <button id="newFeaturesButton" onClick={this.onNewFeaturesButton.bind(this)} className="large alert" title={lang.d('whats_new')}>
                            <span className="icon icon-new-notification"></span>
                        </button>
                    </div>
                }
            </div>

            <div className="center-navigation">
                <nav>
                    { showWorkspacesSwitcher &&
                        <div className="dropdown-menu" onClick={this.openWorkspacesMenu.bind(this)}
                            ref={this.workspacesDropdownRef}>
                            <div className="icon icon-workspace"></div>
                            <div className="badge">{ this.getWorkspaceBadge(context.workspace) }</div>
                            <div className="caption">{ formatWorkspaceName(context.workspace) }</div>
                            <div className={`dropdown-icon icon-arrow-medium-${this.state.workspacesMenuOpened ? 'up' : 'down'}`}></div>
                        </div>
                    }
                    <Link to="/" className={pathname === '/' || pathname.includes('/charts') ? 'active' : undefined}>
                        { lang.d('seating_charts') }
                    </Link>
                    <IfAuthorized role={isCompanyOrSuperAdmin}>
                        <NavLink to="/workspace-settings" activeClassName="active">
                            { lang.d('settings') }
                        </NavLink>
                    </IfAuthorized>
                    <IfAuthorized role={isSuperAdmin}>
                        { !isAdminImpersonating &&
                            <NavLink to="/admin" activeClassName="active">{lang.d('admin')}</NavLink>
                        }
                    </IfAuthorized>
                </nav>
            </div>
            <div className="account">
                {this.renderUpgradeButton()}
                <div className={`menu-button ${this.state.contextualHelpMenuOpened ? 'pressed' : null}`}
                    onClick={this.onHelpMenu.bind(this)} ref={this.helpDropdownRef}>
                    <div className="inset">
                        <button>
                            <span className="icon icon-help"></span>
                        </button>
                    </div>
                    <span
                        className={`dropdown-icon icon-arrow-medium-${this.state.contextualHelpMenuOpened ? 'up' : 'down'}`}></span>
                </div>
                <div className="menu-button account-menu" onClick={this.openAccountMenu.bind(this)}>
                    <div className="inset">
                        <div className="avatar" style={{ backgroundImage: `url(${getGravatarImage(user.email)})` }}></div>
                    </div>
                    <div className="dropdown-icon icon-arrow-medium-down"></div>
                </div>
            </div>
            {this.state.accountMenuOpened &&
            <AccountMenu
                accountName={this.getUserDisplayName(user)}
                avatarUrl={getGravatarImage(user.email)}
                avatarLink={getGravatarProfileUrl(user.email)}
                impersonatedCompanyName={isAdminImpersonating ? context.company.name : undefined}
                onSelect={this.onAccountMenuSelect.bind(this)}
                onClose={this.onAccountMenuClose.bind(this)}
                userOptions={this.renderAccountMenuUserOptions(user, isAdminImpersonating)}
                companyOptions={this.renderAccountMenuCompanyOptions(user, isAdminImpersonating)}
                impersonatorOptions={this.renderAccountMenuImpersonatorOptions(user, isAdminImpersonating)}
                companyName={user.company.name}
            />
            }
        </div>
    }
}

Navigation.propTypes = {
    workspacesList: PropTypes.array,
    user: PropTypes.object,
    pathname: PropTypes.string,
    context: PropTypes.object,
    switchToWorkspace: PropTypes.func,
    setWorkspacesList: PropTypes.func,
    company: PropTypes.object,
    openModalDialog: PropTypes.func,
    updateUser: PropTypes.func.isRequired,
    region: PropTypes.string
}

const mapStateToProps = state => ({
    workspacesList: state.workspaces.list,
    company: state.context.company,
    user: state.user,
    region: state.context.company.region
})

const mapDispatchToProps = dispatch => ({
    setWorkspacesList: (workspaces , nextId) => {
        return dispatch(setWorkspacesList(workspaces, nextId))
    },
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    updateUser: payload => dispatch(updateUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
