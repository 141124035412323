import React from 'react'
import {Redirect} from 'react-router-dom'
import * as PropTypes from 'prop-types'
import queryString from 'query-string'

class RedirectTo extends React.Component {

    render () {
        const queryParams = queryString.parse(this.props.location.search)
        let redirectState;
        if (queryParams.messageKey) {
            redirectState = {
                pathname: queryParams.target,
                state: {messageKey: queryParams.messageKey}
            }
        } else {
            redirectState = {pathname: queryParams.target, state: {}}
        }

        return <Redirect to={redirectState}/>
    }
}

RedirectTo.propTypes = {
    location: PropTypes.object
}

export default RedirectTo
