import axios from 'axios'
import store from '../store'
import { backendUrlOfActiveCompany } from '../domain/regions'

export const regenerateCompanyAdminKey = async (companyId) => {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/regenerate-admin-key`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data.adminKey
}

export const retrieveCompany = async (companyId) => {
    const result = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data
}

export const unsubscribe = async (companyId) => {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/unsubscribe`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data
}

export const resubscribe = async (companyId) => {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/resubscribe`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data
}

export const prepareNewPaymentMethod = async (companyId) => {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/prepare-new-payment-method`,
        auth: {username: store.getState().user.secretKey},
        headers: { 'X-Company-ID': store.getState().context.company.id },
        data: {}
    })
    return response.data
}
