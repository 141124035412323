import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isSuperAdmin } from '../../reducers/user'
import lang from '../../utils/lang'
import FormButton from '../GUI/FormButton'
import { exposeErrors } from '../../utils/errors'
import { openModalDialog } from '../../actions/modalDialog'
import { resubscribe, unsubscribe } from '../../services/companies.service'

class SubscriptionReport extends React.Component {

    render () {
        return <>
            <div className="title">Subscription</div>
            { this.props.data
                ? <div className="subscription-facts">
                    { this.props.data.upcomingSubscription ? this.getSubscriptionFactsWithUpcomingSubscription() : this.getSubscriptionFacts() }
                </div>
                : this.noSubscription()
            }
        </>
    }

    renderSubscriptionLink () {
        if (!isSuperAdmin(this.props.user)) {
            return <div className="description"><Link to="/account/change-plan" className="button">Change pricing plan</Link></div>
        }
    }

    getSubscriptionFacts () {
        const facts = []

        facts.push(
            <div className="fact" key="plan">
                <div className="title">Current plan</div>
                <div className="value">{ this.pricingPlanName(this.props.data) }</div>
                <div className="description">{ this.yearlyUsageDetails() }</div>
                { this.renderSubscriptionLink() }
            </div>
        )

        if (this.props.data.isYearly) {
            const period = this.props.data.period
            facts.push(
                <div className="fact" key="start">
                    <div className="title">Started on</div>
                    <div className="value">{ this.formatDate(this.toFirstDateOfMonth(period.start)) }</div>
                    <div className="separator" />
                    { this.renderRenewalOrExpiration(this.props.data) }
                </div>
            )
        }

        return facts
    }

    getSubscriptionFactsWithUpcomingSubscription () {
        const facts = []

        facts.push(
            <div className="fact" key="plan">
                <div className="title">Current plan</div>
                <div className="value">{ this.pricingPlanName(this.props.data) }</div>
                <div className="separator" />
                <div className="title">Started on</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(this.props.data.period.start)) }</div>
                <div className="description">{ this.yearlyUsageDetails() }</div>
                { this.renderSubscriptionLink() }
            </div>
        )

        const upcomingSubscription =  this.props.data.upcomingSubscription
        facts.push(
            <div className="fact">
                <div className="title">Changing to</div>
                <div className="value">{ this.pricingPlanName(upcomingSubscription) }</div>
                <div className="separator" />
                <div className="title">Starting on</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(upcomingSubscription.period.start)) }</div>
                <div className="separator" />
                <div className="title">Renewing on</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(upcomingSubscription.nextSubscriptionStartsIn)) }</div>
            </div>
        )

        return facts
    }

    renderRenewalOrExpiration (data) {
        if (this.props.data.willUnsubscribeIn) {
            const unsubscribeDate = new Date(data.willUnsubscribeIn.year, data.willUnsubscribeIn.month, 0)
            return <>
                <div className="title">Ends on</div>
                <div className="value">{ this.formatDate(unsubscribeDate) }</div>
                { this.renderReEnableAutomaticRenewalButton() }
            </>
        } else {
            return <>
                <div className="title">Renews on</div>
                <div className="value">{ this.formatDate(this.toFirstDateOfMonth(data.nextSubscriptionStartsIn)) }</div>
                { this.renderStopRenewalButton() }
            </>
        }
    }

    hasYearlyPlanWithRenewalDate () {
        return this.props.data.isYearly && !this.props.data.willUnsubscribeIn
    }

    renderStopRenewalButton () {
        if (!this.hasYearlyPlanWithRenewalDate()) {
            return null
        }
        if (this.props.data.pricingPlan.isEnterprisePlan) {
            return null
        }
        return <div className="action">
            <FormButton onClick={this.stopAutomaticRenewal.bind(this)}>Stop automatic renewal</FormButton>
            <div className="hint">This action won't interrupt your current subscription.</div>
        </div>
    }

    stopAutomaticRenewal () {
        const companyId = this.props.user.companyId
        this.props.openModalDialog({
            title: 'Stop automatic renewal',
            successMessage: 'Automatic renewal stopped',
            onSubmit: async () => {
                await exposeErrors(unsubscribe(companyId)).then(unsubscribeMonth => {
                    this.props.data.willUnsubscribeIn = unsubscribeMonth
                    this.setState({})
                })
            },
            settings: {
                acceptCaption: 'Stop renewal',
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => this.stopAutomaticRenewalInfo()
                }
            }
        })
        return () => true
    }

    stopAutomaticRenewalInfo () {
        return <div className="facts">
            <div className="fact">Your subscription will continue <strong>until {this.formatDate(this.calculateEndOfCurrentSubscription(this.props.data.period))}</strong>.</div>
            <div className="fact">Automatic renewal on {this.formatDate(this.calculateNextRenewalDate(this.props.data.period))} will not happen.</div>
            <div className="fact">After your subscription ends, all of your data will remain and you can resubscribe again later.</div>
        </div>
    }

    renderReEnableAutomaticRenewalButton () {
        if (this.props.data.willUnsubscribeIn === undefined) {
            return null
        }
        if (this.props.data.pricingPlan.isEnterprisePlan) {
            return <p>If you want to re-enable automatic renewal of your subscription, please contact <a href="mailto:sales@seats.io">sales@seats.io</a></p>
        } else {
            const nextRenewalDate = this.formatDate(this.calculateNextRenewalDate(this.props.data.period))
            return <div className="action">
                <FormButton onClick={this.reEnableAutomaticRenewal.bind(this)}>Re-enable automatic renewal</FormButton>
                <div className="hint">This will automatically renew your subscription as of {nextRenewalDate}.</div>
            </div>
        }
    }

    reEnableAutomaticRenewal () {
        const companyId = this.props.user.companyId
        this.props.openModalDialog({
            title: 'Re-enable automatic renewal',
            successMessage: 'Automatic renewal has been re-enabled',
            onSubmit: async () => {
                await exposeErrors(resubscribe(companyId)).then(() => {
                    this.props.data.willUnsubscribeIn = undefined
                    this.setState({})
                })
            },
            settings: {
                acceptCaption: 'Re-enable renewal',
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => this.reEnableAutomaticRenewalInfo()
                }
            }
        })
        return () => true
    }

    reEnableAutomaticRenewalInfo () {
        return <div className="facts">
            <div className="fact">Your subscription will re-start <strong>from {this.formatDate(this.calculateNextRenewalDate(this.props.data.period))}</strong>.</div>
            <div className="fact">This will not affect your current subscription.</div>
        </div>
    }

    calculateNextRenewalDate (period) {
        const nextRenewalDate = new Date(period.end.year, period.end.month)
        return this.toFirstDateOfMonth({month: nextRenewalDate.getMonth() + 1, year: nextRenewalDate.getFullYear()})
    }

    calculateEndOfCurrentSubscription (period) {
        return this.toLastDateOfMonth({month: period.end.month, year: period.end.year})
    }

    toFirstDateOfMonth (month) {
        return new Date(month.year, month.month - 1)
    }

    toLastDateOfMonth (month) {
        return new Date(month.year, month.month, 0)
    }

    formatDate (month) {
        return format(month, 'd MMM yyyy')
    }

    noSubscription () {
        return <>
            <div>You don't have an active subscription</div>
            <Link to="/account/upgrade-now" className="Button">{ lang.d('upgrade') }</Link>
        </>
    }

    yearlyUsageDetails () {
        if (!this.props.data.isYearly) {
            return null
        }
        if (this.props.data.committedMinimum) {
            return <div>You've used <b>{this.props.data.usage}</b> out of <b>{this.props.data.committedMinimum}</b> committed seats.</div>
        }
        return <div>You've used <b>{this.props.data.usage}</b> seats.</div>
    }

    pricingPlanName = (data) => {
        const name = data.pricingPlan.name || 'Custom'
        if (data.isYearly) {
            return `Yearly ${name}`
        } else {
            return `Monthly ${name}`
        }
    }
}

SubscriptionReport.propTypes = {
    data: PropTypes.object,
    user: PropTypes.object,
    openModalDialog: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionReport)
