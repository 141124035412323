import React from 'react'
import { eventsService } from '../../services/events.service'
import format from 'date-fns/format'
import DataTable from '../../components/GUI/DataTable'
import lang from '../../utils/lang'
import { StatusChangesParams } from 'seatsio'
import { cloneStatusChangesParameter } from '../../utils/helperFunctions'
import { exposeErrors } from '../../utils/errors'
import Badge from '../../components/GUI/Badge'
import * as PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import queryString from "query-string";
import { history } from "../../store";

export default class StatusChanges extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            data: [],
            currentId: null,
            parameters: new StatusChangesParams('', 'MATCHES'),
            dateCount: 0,
            statusCount: 1,
            labelCount: 1,
            activeSortColumn: 'date',
            loading: false
        }
        this.hasMoreItems = true
        this.isLoading = false
        this.firstTimeLoading = true
        this.ongoingRequest = null
        this.currentRequestID = null
        this.loadMore = debounce(this.loadMore.bind(this), 1000)
        this.search = this.search.bind(this)
        this.sortByDate = this.sortByDate.bind(this)
        this.sortByLabel = this.sortByLabel.bind(this)
        this.sortByStatus = this.sortByStatus.bind(this)
    }

    componentDidMount () {
        const queryParams = queryString.parse(this.props.location.search)
        if (queryParams.q) {
            const searchParams = JSON.parse(atob(queryParams.q))
            this.setState({
                parameters: {
                    filter: searchParams.filter,
                    filterType: searchParams.filterType
                }
            }, () => {
                this.search(this.state.parameters.filter, undefined, this.state.parameters.filterType)
            })
        } else {
            this.search(this.state.parameters.filter, undefined, this.state.parameters.filterType)
        }
    }

    loadMore () {
        const eventKey = this.props.match.params.eventKey
        const currentItemsInState = [...this.state.data]
        const requestID = Date.now()
        this.currentRequestID = requestID
        this.isLoading = true

        if (this.hasMoreItems && this.state.currentId) {
            this.ongoingRequest = exposeErrors(eventsService.getStatusChangesAfterId(eventKey, this.state.currentId, 15, this.state.parameters))
        } else {
            this.ongoingRequest = exposeErrors(eventsService.getStatusChangesInFirstPage(eventKey, 15, this.state.parameters))
        }

        this.ongoingRequest.then(statusChangesPage => {
            if (this.currentRequestID === requestID) {
                if (statusChangesPage.items.length > 0) {
                    this.hasMoreItems = Boolean(statusChangesPage.nextPageStartsAfter)
                    this.setState({
                        data: [...currentItemsInState, ...statusChangesPage.items],
                        currentId: this.hasMoreItems ? parseInt(statusChangesPage.nextPageStartsAfter) : null,
                        loading: false
                    })
                } else {
                    this.setState({ data: null, currentId: null, loading: false })
                    this.hasMoreItems = false
                }
            }
        })

        this.isLoading = false
        this.firstTimeLoading = false
    }

    triggerSort (param, clickCount, sortColumn) {
        const ascending = this.isAscending(clickCount)
        this.setState({
            data: [],
            currentId: null,
            parameters: ascending ? param.sortAscending() : param.sortDescending(),
            activeSortColumn: sortColumn,
            loading: true
        })
        this.hasMoreItems = true
    }

    search (value, tag, filterType) {
        let searchValue = ''
        if (value && typeof value === 'string') {
            searchValue = value
        }
        const param = cloneStatusChangesParameter(this.state.parameters).withFilter(searchValue, filterType || this.state.parameters.filterType)
        this.setState({
            data: [],
            currentId: null,
            parameters: param
        }, () => {
            history.push('?q=' + btoa(this.serializeParams()))
        })
        this.hasMoreItems = true
    }

    serializeParams () {
        const params = {
            filter: this.state.parameters.filter,
            filterType: this.state.parameters.filterType
        }
        return JSON.stringify(params)
    }

    formatDate (dateInfo) {
        return format(dateInfo, 'd MMM yyyy')
    }

    formatTime (dateInfo) {
        return format(dateInfo, 'HH:mm:ss')
    }

    sortByDate () {
        const clickCount = this.state.activeSortColumn === 'date' ? this.state.dateCount + 1 : this.state.dateCount
        this.setState({ dateCount: clickCount })
        const param = cloneStatusChangesParameter(this.state.parameters).sortByDate()
        this.triggerSort(param, clickCount, 'date')
    }

    sortByLabel () {
        const clickCount = this.state.activeSortColumn === 'label' ? this.state.labelCount + 1 : this.state.labelCount
        this.setState({ labelCount: clickCount })
        const param = cloneStatusChangesParameter(this.state.parameters).sortByObjectLabel()
        this.triggerSort(param, clickCount, 'label')
    }

    sortByStatus () {
        const clickCount = this.state.activeSortColumn === 'status' ? this.state.statusCount + 1 : this.state.statusCount
        this.setState({ statusCount: clickCount })
        const param = cloneStatusChangesParameter(this.state.parameters).sortByStatus()
        this.triggerSort(param, clickCount, 'status')
    }

    isAscending (count) {
        return count % 2 === 1
    }

    getSortDirectionClasses (header) {
        const { dateCount, labelCount, statusCount } = this.state
        const active = this.state.activeSortColumn === header
        let ascending = false
        switch (header) {
        case 'date':
            ascending = this.isAscending(dateCount)
            break
        case 'status' :
            ascending = this.isAscending(statusCount)
            break
        case 'label':
            ascending = this.isAscending(labelCount)
            break
        default:
            break
        }

        return `icon ${!active ? 'icon-arrow-expand-vertical' : ascending ? 'icon-arrow-medium-up' : 'icon-arrow-medium-down'}`
    }

    getHeaderClasses (header) {
        const active = this.state.activeSortColumn === header
        return `sortable ${active && 'selected'} ${(active && this.state.loading) && 'loading'}`
    }

    renderHeaders () {
        return <>
            <th width="24%" onClick={this.sortByDate} className={this.getHeaderClasses('date')}>
                {lang.d('date')}
                <span className={this.getSortDirectionClasses('date')}/>
            </th>
            <th width="18%" onClick={this.sortByLabel} className={this.getHeaderClasses('label')}>
                {lang.d('object')}
                <span className={this.getSortDirectionClasses('label')}/>
            </th>
            <th width="10%" onClick={this.sortByStatus} className={this.getHeaderClasses('status')}>
                {lang.d('status')}
                <span className={this.getSortDirectionClasses('status')}/>
            </th>
            <th width="5%" title={lang.d('quantity')}>
                {lang.d('quantity_short')}
            </th>
            <th width="18%">
                {lang.d('holdtoken')}
            </th>
            <th width="10%">
                {lang.d('order')}
            </th>
            <th width="15%">
                {lang.d('origin')}
            </th>
        </>
    }

    renderRows () {
        if (!this.state.data) {
            return <></>
        }
        return this.state.data.map(statusChange => (
            <tr key={statusChange.id}>
                <td>
                    <strong className="date nowrap">{this.formatDate(statusChange.date)}</strong>
                    <span className="time nowrap">
                        {this.formatTime(statusChange.date)}<span
                            className="soft">.{format(statusChange.date, 'SSS')}</span>
                    </span>
                </td>
                <td>{this.objectLabel(statusChange)}</td>
                <td className="fixed-width">{statusChange.status}</td>
                <td>{statusChange.quantity}</td>
                <td className="fixed-width">{statusChange.holdToken}</td>
                <td className="fixed-width">{statusChange.orderId}</td>
                <td className="fixed-width">{this.formatOrigin(statusChange.origin)}</td>
            </tr>
        ))
    }

    setSearchValue (objectLabel) {
        this.setState({
            parameters: {
                filter: objectLabel,
                filterType: 'MATCHES'
            }
        })
        this.search(objectLabel, '', 'MATCHES')
    }

    objectLabel (statusChange) {
        return <>
            <pre>
                <a href="#" onClick={() => this.setSearchValue(statusChange.objectLabel)}> { statusChange.objectLabel }</a>
            </pre>
            { !statusChange.isPresentOnChart &&
                <>
                    <Badge type="deleted" icon="x" title={this.notPresentOnChartReasonInfo(statusChange)}>
                        { lang.d('missing') }
                    </Badge>
                </>
            }
            { statusChange.displayedLabel && statusChange.objectLabel !== statusChange.displayedLabel &&
                <span className="display-label">{lang.d('displayedAs')} {statusChange.displayedLabel}</span>
            }
        </>
    }

    formatOrigin (origin) {
        if (!origin) {
            return lang.d('n/a')
        }
        if (origin.ip) {
            return `${this.formatOriginType(origin.type)} - ${origin.ip}`
        }
        return this.formatOriginType(origin.type)
    }

    formatOriginType (originType) {
        return lang.d('STATUS_CHANGE_ORIGIN_TYPE_' + originType)
    }

    render () {
        return <DataTable
            waitingForFirstData={this.firstTimeLoading}
            hasData={this.state.data !== null && this.state.data.length > 0}
            emptyList={this.state.data === null}
            hasMore={this.hasMoreItems && !this.isLoading}
            loadMore={this.loadMore}
            headers={this.renderHeaders()}
            rows={this.renderRows()}
            onSearch={this.search}
            searchValue={this.state.parameters.filter}
            searchCaption={lang.d('search_status_changes_hint')}
            searchFilterTypes={['MATCHES', 'CONTAINS', 'BEGINS_WITH', 'ENDS_WITH']}
            searchFilterType={this.state.parameters.filterType}
        />
    }

    notPresentOnChartReasonInfo (statusChange) {
        switch (statusChange.notPresentOnChartReason) {
        case 'SWITCHED_TO_BOOK_BY_SEAT': return lang.d('switched_to_book_by_seat_info')
        case 'SWITCHED_TO_BOOK_WHOLE_TABLE': return lang.d('switched_to_book_whole_table_info')
        default: return lang.d('renamed_or_deleted_info')
        }
    }
}

StatusChanges.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object
}
